<template>
  <div v-bind="$attrs" :class="cn('mask-to-transparent grid', classes?.root, props.class)">
    <span
      :style="textVariants({ rank })"
      :class="
        cn('styled-num col-start-1 row-start-1', { 'styled-num--small': variant === 'small' }, classes?.text)
      "
    >
      <slot />
    </span>

    <span
      :class="
        cn(
          'styled-num styled-num--shadow col-start-1 row-start-1',
          { 'styled-num--small': variant === 'small' },
          classes?.text,
          classes?.innerShadow
        )
      "
    >
      <slot />
    </span>
  </div>
</template>

<script setup lang="ts">
import { type StyleVariantProps, sva } from "style-variance-authority"

import { Risk } from "@finq/app-base/lib/types/risk"

const props = defineProps<{
  class?: ClassValue
  rank: StyleVariantProps<typeof textVariants>["rank"]
  classes?: Partial<{
    root: ClassValue
    text: ClassValue
    innerShadow: ClassValue
  }>
  variant?: "default" | "small"
}>()

const textVariants = sva({
  defaultVariants: { rank: "gray" },
  variants: {
    rank: {
      gray: { color: "rgba(0,0,0,0.03)" },
      "1": { color: Risk.TEST_GRADIENT[RiskLevel.One] },
      "2": { color: Risk.TEST_GRADIENT[RiskLevel.Two] },
      "3": { color: Risk.TEST_GRADIENT[RiskLevel.Three] },
      "4": { color: Risk.TEST_GRADIENT[RiskLevel.Four] },
      "5": { color: Risk.TEST_GRADIENT[RiskLevel.Five] },
    },
  },
})
</script>

<style lang="scss" scoped>
.mask-to-transparent {
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0) 90%);
}

.styled-num {
  font-size: 8rem;
  line-height: 0.8;

  &--small {
    font-size: 6.25rem;
  }

  &--shadow {
    background-color: #000;
    color: transparent;
    text-shadow: 0.5px 0.5px 1.25px rgba(255, 255, 255, 0.75);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    mix-blend-mode: overlay;
    opacity: 0.2;
  }
}
</style>
